import { reactive, readonly, computed } from "@vue/composition-api";

const state = reactive({
  previewTarget: {},
});

const setPreviewTarget = (val) => (state.previewTarget = val);

const isPreviewing = computed(() => {
  if (Object.keys(state.previewTarget).length) return true;
  return false;
});

export function useViewAs() {
  return {
    state: readonly(state),

    setPreviewTarget,
    isPreviewing,
  };
}
