<template>
  <div>
    <v-dialog v-model="isPreviewing" fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="d-flex flex-column">

        <v-card-title>
          <span>Previewing As {{ previewState.previewTarget.username }}</span>
          <v-btn @click="setPreviewTarget({})" class="ml-auto">Close</v-btn>
        </v-card-title>

        <v-card-text class="flex-grow-1">
          <iframe id="previewWindow" ref="iframeWindow" :src="iframeUrl" frameborder="0" @load="handleIframeLoaded"></iframe>
        </v-card-text>
        
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@axios";
import { ref } from "@vue/composition-api";
import { useViewAs } from "@/composables/accounts/view-as";

export default {
  name: "ViewAs",
  setup() {
    const { state: previewState, isPreviewing, setPreviewTarget } = useViewAs();
    const iframeWindow = ref(null);

    async function handleIframeLoaded() {
      const { data: previewLoginCreds } = await axios.post("/preview-login", {
        userId: previewState.previewTarget.id,
      });

      iframeWindow.value.contentWindow.postMessage(
        previewLoginCreds,
        process.env.VUE_APP_SAAS_URL
      );
    }

    return {
      iframeUrl: `${process.env.VUE_APP_SAAS_URL}?accessMode=preview`,
      previewState,
      setPreviewTarget,
      isPreviewing,

      iframeWindow,
      handleIframeLoaded,
    };
  },
};
</script>

<style>
#previewWindow {
  width: 100%;
  min-height: 750px;
}
</style>
