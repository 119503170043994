<template>
  <div class="d-flex flex-column dashboard px-4 py-4 w-100">
    <h2 class="mb-4 cursive-font">Preview Accounts</h2>

    <v-alert dismissible>
      From this page, you can view the main HireHeroes website from the POV of
      one of the users.
    </v-alert>

    <table-with-filters :options="tableOptions" :customColumns="customColumns">

      <!-- User Profile -->
      <template v-slot:user-name="{ item }">
        <div>
          <v-avatar size="30px">
            <img :src="resolveProfilePic(item)" alt="" />
          </v-avatar>
          <span class="ml-2">{{ item.username }}</span>
        </div>
      </template>

      <!-- User Profile -->
      <template v-slot:type="{ item }">
        <div>
          <span class="ml-2">{{ item.type == 'va'
            ? "Virtual Client" : "Client" }}</span>
        </div>
      </template>

      <!-- preview Button -->
      <template v-slot:preview-btn="{ item }">
        <div class="box">
          <v-btn color="success" small text @click="setPreviewTarget(item)"> Preview </v-btn>
          <v-btn color="info" small text @click="openDialog(item)" :disabled="item.type == 'va'"> Manage Hours </v-btn>
          <v-btn color="primary" small text @click="deleteAccount(item.id)"> Archive </v-btn>
        </div>
      </template>

    </table-with-filters>


    <view-as v-if="isPreviewing"></view-as>

    <v-alert :type="isError ? 'error' : 'success'" class="popup-message" v-if="isVisible">
      {{ message }}
    </v-alert>

    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h4">
          Client Wallet
        </v-card-title>
        <v-alert :type="isWalletError ? 'error' : 'success'" text dense v-if="isWalletVisible">{{
          walletMessage
        }}</v-alert>

        <v-card-text>
          <v-small>You can ADD or REMOVE hours by typing hours. Example for Addition type hour eg. 10 and for Deduction
            type " - " in front of hours eg. -10</v-small>

          <div class="wallet-balance">
            <span>Current Balance : </span>
            <span> {{ convertMinsToHrsMins(wallet.minutes) }} </span>
          </div>
          <v-text-field v-model="textBox" type="number" outlined label="Hours" placeholder="Enter Hours" max="480"
            step="10" hide-details="auto" class="mb-2">
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="dialog = false">
            close
          </v-btn>
          <v-btn color="green darken-1" text @click="update()">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { resolveProfilePic } from "@/helpers";
import { useViewAs } from "@/composables/accounts/view-as.js";
import { ref } from '@vue/composition-api'
import ViewAs from "@/components/accounts/ViewAs.vue";
import TableWithFilters from "@/components/common/TableWithFilters.vue";
import axios from '@axios';

export default {
  name: "Accounts",
  components: { TableWithFilters, ViewAs },
  setup() {
    const isVisible = ref(false)
    const isError = ref(false)
    const message = ref(null)
    const dialog = ref(false)
    const switch1 = ref(true)
    const textBox = ref('')
    const wallet = ref({})
    const user = ref({})
    const isWalletVisible = ref(false)
    const isWalletError = ref(false)
    const walletMessage = ref(null)

    const tableHeaders = [
      { text: "User", value: "userName", sortable: true },
      { text: "Email", value: "email", sortable: false },
      { text: "Type", value: "type", sortable: false },
      { text: "", value: "previewBtn", sortable: false }
    ];

    const openDialog = async (item) => {
      dialog.value = true
      user.value = item
      const { data: walletDetail } = await axios.post('/wallet', { id: item.id })
      if (walletDetail) {
        wallet.value = walletDetail
        convertMinsToHrsMins(walletDetail.minutes)
      }
    }

    const customColumns = [
      { slotName: "user-name", itemValue: "userName" },
      { slotName: "preview-btn", itemValue: "previewBtn" }
    ];

    const filterFields = [
      { label: "Client Name", value: "username", component: "v-text-field" },
      { label: "Client Email", value: "email", component: "v-text-field" },
      { label: "User Type", value: "type", component: "v-select", items: ['client', 'va'] },
    ];

    const { setPreviewTarget, isPreviewing } = useViewAs();

    const setAlertData = (msg = 'Hello', err, show, duration) => {
      message.value = msg
      isError.value = err
      isVisible.value = show

      setTimeout(() => {
        message.value = null
        isError.value = false
        isVisible.value = false
      }, duration)
    }

    const deleteAccount = async (id) => {
      try {
        const data = await axios.patch('/admin/disable-user', {
          userID: id
        })
        if (data) {
          setAlertData('Account Disabled Successfully', false, true, 2500)
        }
      } catch (err) {
        setAlertData('Account Not Disabled', true, true, 2500)
        throw err
      }
    }

    const convertMinsToHrsMins = (mins) => {
      let h = Math.floor(mins / 60);
      let m = mins % 60;
      h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
      m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
      return `${h}h:${m}m`;
    }

    const displayWalletMessage = (err, msg = '', isVisible) => {
      isWalletError.value = err
      walletMessage.value = msg
      isWalletVisible.value = isVisible
    }

    const update = async () => {
      try {
        await axios.post('/wallet/add-hours', {
          minutes: (textBox.value * 60),
          client_id: user.value.id
        })
        const { data: walletDetail } = await axios.post('/wallet', { id: user.value.id })

        if (walletDetail) {
          wallet.value = walletDetail
          convertMinsToHrsMins(walletDetail.minutes)
        }
        displayWalletMessage(false, `${textBox.value} Hours Added`, true)
        setTimeout(() => {
          displayWalletMessage(false, '', false)
          textBox.value = ''
        }, 1000)
      } catch (error) {
        displayWalletMessage(true, `${textBox.value * 60} Hours Not Added`, true)
        setTimeout(() => {
          displayWalletMessage(false, '', false)
        }, 1000)
      }

    }

    return {
      tableHeaders,
      deleteAccount,
      openDialog,
      tableOptions: {
        endpoint: "/admin/users",
        headers: tableHeaders,
        filtersTitle: "Filter Users",
        filterFields,
      }, textBox,
      convertMinsToHrsMins,
      customColumns,
      switch1,
      isPreviewing,
      setPreviewTarget,
      dialog,
      resolveProfilePic,
      isVisible,
      isError,
      message, wallet, update,
      isWalletVisible,
      isWalletError,
      walletMessage
    };

  },
};
</script>

<style scoped>
.wallet-balance {
  font-size: 1.1rem;
  padding: 1rem 0 1rem 0;
}

.box {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.popup-message {
  position: fixed;
  right: 10px;
  top: 75px;
  width: 300px !important;
  z-index: 999;
  padding: 0.8rem;
  border-radius: 10px;
  font-weight: 500;
}
</style>